
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import { Pageniation, Loading } from "@/utils/decorator";
import t from "@common/src/i18n/t";
import { MessageBox, Message } from 'element-ui';
const userMember = namespace("userMember");
const base = namespace("base");
const consult = namespace("consult");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class AppVersion extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @userMember.Action getUserMemberList;
  @userMember.Action getMemberLevelList;
  @userMember.Action updateMemberStateInfo;

  dayjs = dayjs;
  visible: boolean = true;
  searchForm = {
    applicationCode: ""
  };
  emptyToLine = emptyToLine;
  memberLevelList: any[] = [];
  storeTypeList: any[] = [];
  publisherList: any = [];
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    console.log(this.searchForm, "searchfrom");

    this.loadListData();
  }
  created() {
    this.init();
  }

  async init() {
    this.loadListData();
  }

  @Loading()
  loadListData() {
    return this.getMemberLevelList().then(data => {
      this.memberLevelList = data.data;
      return data;
    });
  }
  handleEdit(row) {
    console.log("rowid:" + row.id);
    this.$router.push(`/user/member-level-edit/${row.levelId}`);
  }
  handleEditStatus(row) {
    var updateState = row.state == 1 ? 0 : 1;
    console.log("rowid:" + row.id + " state:" + row.state + "updateState:" + updateState);
    MessageBox.confirm("请确保等级列表划分是否正确?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
      this.updateMemberStateInfo({
        id: row.id,
        state: updateState
      }).then(() => {
        this.$message.success("修改成功");
        this.loadListData();
      });
    });

  }
}
